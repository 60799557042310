import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import SecondaryHero from "../components/secondary-hero";
import EventSection from "../components/event-section";

const VolunteerTemplate = ({ data: { volunteer, site, heroImgMain } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Volunteering",
				item: {
					url: `${siteUrl}/volunteering`,
					id: `${siteUrl}/volunteering`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `Volunteering Stories`,
				item: {
					url: `${siteUrl}/volunteering/volunteering-stories`,
					id: `${siteUrl}/volunteering/volunteering-stories`,
				},
			},
			{
				"@type": "ListItem",
				position: "4",
				name: `${volunteer.title}`,
				item: {
					url: `${siteUrl}/volunteering/volunteering-stories/${volunteer.slug}`,
					id: `${siteUrl}/volunteering/volunteering-stories/${volunteer.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${volunteer.title} | Friends of Eastbourne Hospital`}
				description=""
				openGraph={{
					url: `${siteUrl}/volunteering/volunteering-stories/${volunteer.slug}`,
					title: `${volunteer.title} | Friends of Eastbourne Hospital`,
					description: "",
					type: "article",
					images: [
						{
							url: `${heroImgMain?.localFile.publicURL}`,
							width: `${heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${heroImgMain?.altText}`,
						},
					],
				}}
			/>
			<div>
				<section>
					<SecondaryHero
						title={volunteer.volunteerFields.name}
						description={`"${volunteer.volunteerFields.description}"`}
					/>
				</section>
				<section>
					<Container className=" py-md-7 pt-5">
						<Row>
							<Col>{parse(volunteer.content)}</Col>
						</Row>
					</Container>
				</section>
			</div>
			<section>
				<EventSection />
			</section>
		</Layout>
	);
};

export default VolunteerTemplate;

export const pageQuery = graphql`
	query VolunteerById($id: String!) {
		volunteer: wpVolunteer(id: { eq: $id }) {
			id
			title
			content
			slug
			volunteerFields {
				description
				name
				cardImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 50
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}
		heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 50
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
